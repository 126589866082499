import { Form, SubmissionError, useSelector } from '@innedit/formidable';
import { EvenementModel, InneditContext } from '@innedit/innedit-react';
import { PageProps } from 'gatsby';
import React, { FC, SyntheticEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../components/Button';
import Content from '../components/Content';
import Demandes from '../components/Demandes';
import Layout from '../components/Layout';
import contact from '../params/contact.json';
import { StateProps } from '../reducers';

const IndexPage: FC<PageProps> = function ({ location: { pathname } }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<boolean>(false);
  const { cart, demande, removeCartItem, removeDemandeItem, clearDemande } =
    useContext(InneditContext);

  const { user: stateUser } = useSelector((state: StateProps) => state);
  const authUser = stateUser ? stateUser.authUser : undefined;
  const user = stateUser && stateUser.item ? stateUser.item : undefined;

  const handleSendContact = (values: {
    address: string;
    city: string;
    email: string;
    firstName: string;
    lastName: string;
    message: string;
    phone: string;
    zip: string;
  }) => {
    if (!values.email) {
      throw new SubmissionError({ email: t('form.contact.email.required') });
    }

    if (!isPending) {
      setIsPending(true);

      EvenementModel.create({
        data: {
          object: {
            language,
            ...values,
            cartProducts: cart && cart.map(item => item.id),
            channel: process.env.GATSBY_ID_CHANNEL,
            priceProducts: demande && demande.map(item => item.id),
          },
        },
        type: 'request.initialized',
        userId: authUser?.uid,
      })
        .then(() => {
          clearDemande();
          setIsSend(true);

          return setIsPending(false);
        })
        .catch(error => {
          console.error(error);

          return setIsPending(false);
        });
    }
  };

  const handleRemoveCartItem = (event: SyntheticEvent<HTMLButtonElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    if (id) {
      removeCartItem(id);
    }
  };

  const handleRemoveDemandeItem = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    const id = event.currentTarget.getAttribute('data-id');
    if (id) {
      removeDemandeItem(id);
    }
  };

  if (isSend) {
    return (
      <Layout
        breadcrumbs={[]}
        pathname={pathname}
        showTitle
        title="pages.contact.title"
      >
        <Content className="mx-6 md:mx-auto !max-w-screen-sm">
          <p className="text-center">{t('pages.contact.demande-envoyee')}</p>
          <p className="text-center">
            {t('pages.contact.nous-reviendrons-vers-vous')}
          </p>
          <h3 className="text-center text-xl mt-12 mb-6">
            {t('pages.contact.envie-de-voir')}
          </h3>
          <Button status="neutral" to={t('pages.inventaire.pathname')}>
            {t('pages.contact.retourner-inventaire')}
          </Button>
          <p className="mt-12 text-center">{t('infos.dealer.name')}</p>
        </Content>
      </Layout>
    );
  }

  return (
    <Layout pathname={pathname} showTitle title="pages.contact.title">
      <Content className="flex flex-col p-6 max-w-screen-md w-full mx-auto">
        {((demande && demande.length > 0) || (cart && cart.length > 0)) && (
          <>
            {demande && demande.length > 0 && (
              <Demandes removeItem={handleRemoveDemandeItem} values={demande} />
            )}
            {cart && cart.length > 0 && (
              <Demandes removeItem={handleRemoveCartItem} values={cart} />
            )}
            <div className="flex items-center justify-center mb-12">
              <Button
                className="text-center"
                status="neutral"
                to={t('pages.inventaire.pathname')}
              >
                {t('pages.contact.ajouter-de-nouveaux-produits')}
              </Button>
            </div>
          </>
        )}

        <div className="my-12">
          <h2 className="text-2xl text-center mb-6">
            {t('form.contact.title')}
          </h2>
          <Form
            className="w-full"
            datas={contact}
            enableReinitialize
            footerProps={{ className: 'flex !justify-center my-6' }}
            hideSubmitButton
            initialValues={user}
            isSubmissive={!isPending}
            name="contact"
            onSubmit={handleSendContact}
          >
            <div className="flex mt-12 items-center justify-center">
              <Button loading={isPending} type="submit">
                {t('form.contact.submit.label')}
              </Button>
            </div>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default IndexPage;
