import { CommandeProduitType } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import React, { FC, SyntheticEvent } from 'react';

import DemandesItem from './Item';

interface DemandesProps {
  className?: string;
  removeItem?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  values?: CommandeProduitType[];
}

const Demandes: FC<DemandesProps> = function ({
  className,
  removeItem,
  values = [],
}) {
  return (
    <div className={className || 'box-border'}>
      <div className="w-full">
        {values.map(item => (
          <DemandesItem
            key={objectHash(item)}
            item={item}
            removeItem={removeItem}
          />
        ))}
      </div>
    </div>
  );
};

export default Demandes;
