import { ProduitModel } from '@innedit/innedit-react';
import { CommandeProduitType, ProduitNode } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../Button';

const ButtonSC = styled.button`
  border: none;
  background: none;
  margin-right: 0;
  padding: 5px;
  color: #aeadb3;
  transition: color 0.2s ease;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  &:before {
    content: '✕';
  }

  span {
    display: none;
  }
`;

const DemandesItem: FC<{
  item: CommandeProduitType;
  removeItem?: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = function ({ item, removeItem }) {
  const {
    i18n: { language },
  } = useTranslation();

  const [produit, setProduit] = useState<ProduitNode>();

  useEffect(() => {
    let isMounted = true;

    ProduitModel.get(item.id)
      .then(documentSnapshot => {
        if (isMounted) {
          setProduit(documentSnapshot);
        }

        return isMounted;
      })
      .catch(console.error);

    return () => {
      isMounted = false;
    };
  }, [item.id]);

  if (!produit) {
    return null;
  }

  const photo =
    produit.medias &&
    produit.medias.length > 0 &&
    `${produit.medias[0].origin}/w_120,h_120/${produit.medias[0].pathname}`;

  let to = `/${language}/`;
  if ('fr' === language) {
    to += `produit/${produit.pathname}/`;
  } else {
    to += `product/${produit.pathname}/`;
  }

  return (
    <div
      key={produit.id}
      className="relative flex flex-row items-startflex-wrap mb-3"
    >
      {photo && (
        <figure className="mr-3 py-1.5 w-14">
          <img alt={produit.name} className="m-0 block w-full" src={photo} />
        </figure>
      )}

      <div className="flex-1 mr-3 flex flex-col text-md">
        <h3 className="mb-3">
          <Button className="!justify-start" status="link" to={to}>
            {'en' === language && produit.traductions?.en?.name
              ? produit.traductions.en.name
              : produit.name}
          </Button>
        </h3>

        <span className="italic">{produit.reference}</span>
      </div>

      {removeItem && (
        <ButtonSC data-id={item.id} onClick={removeItem} type="button">
          <span>Retirer</span>
        </ButtonSC>
      )}
    </div>
  );
};

export default DemandesItem;
